import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <span>
    <span>NOT FOUND</span>
  </span>
)

export default NotFoundPage
